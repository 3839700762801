.section-component{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    background-color: rgb(87, 87, 87);
    &.rotate{
        .border-top{
            display:none;
        }
        margin-top:-15vh !important;
   
        .footer-component{
            padding:calc(15vh + 20px) 20px 20px 20px !important;
        }
        
    }
    .footer-component{
        // min-height:10vw;
        width:100%;
       
        z-index: 50;
        position: relative; 
        padding:calc(12vh + 20px) 20px 20px 20px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        color:white;
    }
    .border-top{
        clip-path: url(#section-divider-643dca23353a5d3c147cc37e);
        position: absolute;
        top:-60vh;
        background-color:rgb(255, 255, 255);
        height:70vh;
        width:100%;
        z-index: 10;
    }
    a{
        color:white !important;
        text-decoration: none;
    }
    
    .logo-container{
        display: flex;
        height:100%;
        align-items: center;
        justify-content: space-between;
        a{
            display: block;
        }
        
    }

   .container{
    height:100%;
   }
   
    .logo{    
        cursor:pointer;
        height:calc(25px + 0.5vw);
       
    }
   
}