.navbar-component{
    z-index: 1000;
    position: absolute;
    width:100%;
    top:0;
    .navbar-brand{
        cursor:pointer;
        img{
            position: absolute;
            top:35px;
            height:calc(25px + 0.5vw);
            object-fit: contain;
            @media only screen and (max-width: 768px) {
                height:20px; 
            }
        }
    }

    .navbar{
        padding:30px 4vw;
        width:100%;
        display: flex;
        justify-content: space-between;
        #responsive-navbar-nav{
            justify-content: flex-end;
        }
      
        .navbar-nav{
            display: flex;
            align-items: center;
           &:hover{
            .nav-link{
                color:rgba(255, 255, 255, 0.419);

                }
            }
        }
        .nav-link{
            letter-spacing: 1.2px;
            margin:0 10px;
            color:white;
            font-size:14px;
            font-weight: 600 !important;
            &:hover{
                color:white !important;
            }
          
        }
    }

   
}
