.primary-btn-component{
    display: flex;
    button{
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: hsla( 200.69,80.11%,35.49% ,1);
        font-size: calc(.9 * 1rem);
        padding: 1.2em 2.004em;
        letter-spacing: .02em;
        border-radius: 0.4rem;
        outline: none;
        border:0;
        color:white;
        
    }
}